.entry {
	background-color: #154734c9;
	padding: 30px;
}

.entry__title {
	color: #FEFBEA;
	font-weight: unset;
	margin: 0 0 10px 0;
}

.entry__form {
	display: flex;
	flex-direction: column;
}

.entry__form-inputs {
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: center;
}

.entry__input {
	height: 50px;
	width: 60%;
	margin-right: 10px;
	border-radius: 5px;
	background-color: #FEFBEA;
	font-size: 16pt;
	font-weight: 600;
	box-sizing: border-box;
	padding-left: 10px;
}

.entry__button {
	all: unset;
	flex-grow: 1;
	background-color: #862633;
	color: #FEFBEA;
	font-family: 'Baskervville', serif;
	font-size: 16pt;
	border-radius: 5px;
	cursor: pointer;
}

.entry__button:hover {				
	filter: brightness(1.25)
}

.entry__validation {
	margin-top: 10px;
	border-radius: 5px;
	background-color: #862633;
	padding: 10px 0 10px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.entry__validation>img {
	filter: brightness(0) invert(1);
}

.entry__validation>p {
	all:unset;
	color: white;
	margin-left: 5px;
	font-size: 12pt;
}