.main {
	background-color: #154734c9;
	padding: 30px;
	min-width: 400px;
	border-radius: 15px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.main__image-container {
	display: flex;
	flex-direction: column;
	width: 250px;
	height: 313px;
}

.main__title {
	color: #FEFBEA;
	font-weight: unset;
	margin: 0 0 10px;
}

.main__subtitle {
	margin-top: 0;
	color: #FEFBEA;
	font-weight: unset;
	font-size: 16pt;
}

.main__links {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.main__links>a {
	background-color: #862633;
	color: #FEFBEA;
	box-sizing: border-box;
	border-radius: 5px;
	padding: 15px;
	min-width: 250px;
	font-size: 14pt;
	text-decoration: unset;
}

.main__links>a:hover {
	filter: brightness(1.25)
}

.main__links>a:nth-child(2) {
	margin-top: 20px;
	margin-bottom: 20px;
}



@media (max-width: 350px) {
	.main {
		min-width: 300px;
	}
}