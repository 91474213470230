.layout__header {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100px;
	background-color: #154734;
}

.layout__background {
	height: calc(100vh - 150px);
	background-position: center;
	background-size: cover;
}

.layout__children {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
	overflow-y: auto;
}

.layout__footer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 50px;
	background-color: #862633;
	color: #FEFBEA;
}